<template>
  <div>
    <DadosSistemas />
  </div>
</template>

<script>
import DadosSistemas from '@/components/Cliente/DadosSistemas'
export default {
  components: {DadosSistemas},
  data(){
    return{}
  }
}
</script>

<style>

</style>