<template>
  <v-container>

    <div>
            <v-card class="" elevation="" max-width="" >
                <v-card-title primary-title>
                    Contato Plantão Hybrid ( Serviço de Hospedagem dos servidores)
                </v-card-title>

                <div style="display:flex;width:100%;max-width:100%;">
                    <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                        <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                            <h4>Plantão</h4>
                            <div >Contato Whatsapp: 84 98152-0290</div>
                        </div>
        
                    </div>
                </div>

            </v-card>
    </div>

    <div style="margin-top:20px;">
      <v-card class="" elevation="" max-width="" >
        <v-card-title primary-title>
            Dados Servidores
        </v-card-title>

        <div style="display:flex;width:100%;max-width:100%;">
            <div style="display:flex;flex-direction:row;padding:20px;flex-wrap: wrap;">
                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>Firewall</h4>
                    <div>Nome Maquina:ubuntu01</div>
                    <div>dc.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.114</div>
                    <div>IP Interno:172.17.0.1</div>
                </div>

                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>CockPit</h4>
                    <div>Nome Maquina:ubuntu02</div>
                    <div>dc2.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.115</div>
                    <div>IP Interno:172.17.0.2</div>
                </div>
                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>Ocomon</h4>
                    <div>Nome Maquina:ubuntu03</div>
                    <div>ocomon.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.116</div>
                    <div>IP Interno:172.17.0.3</div>
                </div>
                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>FdGuest</h4>
                    <div>Nome Maquina:ubuntu04</div>
                    <div>*.fdguest.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.117</div>
                    <div>IP Interno:172.17.0.4</div>
                </div>

                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>Cardápio Digital</h4>
                    <div>Nome Maquina:ubuntu05</div>
                    <div>*.cd.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.118</div>
                    <div>IP Interno:172.17.0.5</div>
                </div>

                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>ES-Task</h4>
                    <div>Nome Maquina:ubuntu06</div>
                    <div>es.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.116</div>
                    <div>IP Interno:172.17.0.6</div>
                </div>

                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>Zabbix</h4>
                    <div>Nome Maquina:ubuntu07</div>
                    <div>zabbix-es.fd.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.114</div>
                    <div>IP Interno:172.17.0.7</div>
                </div>

                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>Ficha Digital</h4>
                    <div>Nome Maquina:ubuntu08</div>
                    <div>*.fd.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.115</div>
                    <div>IP Interno:172.17.0.8</div>
                </div>

                <div style="margin-right:20px;margin-bottom:20px;padding:5px;border:1px solid #cbcbcb;border-radius:10px;width:300px;display:flex;flex-direction:column;">
                    <h4>PostgreSQL</h4>
                    <div>Nome Maquina:ubuntu09</div>
                    <div>*.fd.economysoftware.com.br</div>
                    <div>IP Pub:201.76.149.115</div>
                    <div>IP Interno:172.17.0.9</div>
                </div>
               
                
            </div>
        </div>

        </v-card>
    </div>


   




  </v-container>
</template>

<script>



export default {
  components: {},
  data(){
    return{
      
    }
  },
  methods:{
    
  },
  async mounted(){
    
  }
}
</script>

<style lang="scss" scoped>
.header{
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.section-header{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1em 0;
}

.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;

  ul{
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #eff3e7;
    padding: 1em;
    width: 100%;

    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 50px;
      background: #eff3e7;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    }
  }
}

.wrapper-list{
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.btn{
      padding: .75em 1em;
      line-height: 1.5;
      color: white;
      font-weight: bold;
      letter-spacing: 0.02em;
      background: #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
  }

.grow{
  flex: 1;
}

.containerFiltros {
  // display: flex;
  // justify-content: flex-start;
  flex-direction: column;
  // align-items: center;
  // margin-bottom: 20px;
  // width: 70vw;
  // margin: auto;
  // background-color: red;
  // // margin-bottom: 20px;
  display: flex;
  padding: 1em;
  margin: 0 auto;
  width: 80vw;
  max-width: 100%;

  
  margin: 1em auto;

  height: fit-content;
  border-radius: 10px;

}

.inputPesquisa {
  background-color: white;
  margin: 0 auto;
  height: 50px;
  padding: 15px;
  border-radius: 8px;
  width: 80vw;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
}

.containerFiltroSistema {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.select {
  width: 100%;
  margin-top: 20px;
}

.btnAddFiltro {
  background-color: #1377f2;
  border-radius: 5px;
  width: 200px;
  height: 30px;
  cursor: pointer;
  color: white;
}

.btnLimarFiltro {
  background-color: #fb8c00;
  border-radius: 5px;
  width: 150px;
  height: 30px;
  cursor: pointer;
  color: white;
  margin-left: 10px;
}

</style>